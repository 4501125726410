<template>
  <div class="section-wrapper section-wrapper-variant-d">
    <SectionAboutUsSecondary />
  </div>
</template>

<script>
import SectionAboutUsSecondary from "@/components/SectionAboutUsSecondary.vue";

export default {
  name: "AboutUsView",
  components: {
    SectionAboutUsSecondary,
  },
};
</script>
