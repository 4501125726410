<template>
  <div class="section-wrapper section-wrapper-variant-d">
    <SectionImprint />
  </div>
</template>

<script>
import SectionImprint from "@/components/SectionImprint.vue";

export default {
  name: "ImprintView",
  components: {
    SectionImprint,
  },
};
</script>
