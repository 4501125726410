<template>
  <div class="section-wrapper section-wrapper-variant-d">
    <SectionPrivacy />
  </div>
</template>

<script>
import SectionPrivacy from "@/components/SectionPrivacy.vue";

export default {
  name: "PrivacyView",
  components: {
    SectionPrivacy,
  },
};
</script>
