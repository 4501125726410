<template>
  <section class="section-intro-main">
    <img
      class="intro-background"
      src="../../public/img/intro/placeholder.jpg"
      alt="dummy"
    />
    <div class="logo-slogan-container">
      <img class="logo" src="../../public/img/logo.png" alt="dummy" />
    </div>
  </section>
</template>

<script>
export default {
  name: "IntroScene",
};
</script>
